import React from "react"
import { navigate } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import ProgressTracker from "../components/ProgressTracker"
import Intro from "../components/Intro"
import Button from "../components/Button"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <ProgressTracker currentStep={0} />
    <Intro
      title="Welcome!"
      text={[
        "We are working on a Dutch national design system. And we would like to use your knowledge and experience. You can help us by filling out this simple, 3-step questionnaire.",
      ]}
    />
    <Intro
      subtitle="What is a design system? "
      text={[
        "A central design system provides organizations with building-blocks which can be used for prototyping and development of websites. This contributes to a more consistent user experience and high-quality government services.",
      ]}
    />
    <Button onClick={() => navigate("/details")} label="Get Started" />
  </Layout>
)

export default IndexPage
